#clubdesc {
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-light);
    gap: 7.5rem;
}

#clubdesc-content,
#clubdesc-content-reverse {
    align-items: center;
}

.clubdesc-text {
    max-width: 50%;
}

.clubdesc-text h1 {
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.clubdesc-text p {
    align-self: stretch;
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
}

#clubdesc-content img,
#clubdesc-content-reverse img {
    max-width: 40%;
}

@media screen and (max-width: 950px) {
    #clubdesc-content {
        flex-direction: column-reverse;
        width: 100%;
        box-sizing: border-box;
    }

    #clubdesc-content-reverse {
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
    }

    #clubdesc-content img,
    #clubdesc-content-reverse img {
        max-width: 100%;
        height: auto;
    }

    .clubdesc-text {
        max-width: 100%;
    }
}
