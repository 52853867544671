#about-header-img {
    position: absolute;
    /* width: 45vw; */
    top: 0;
    left: 0;
    height: 100%;
}

.aboutus-hero-text {
    display: flex;
    padding: 20.625rem 0 5.625rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.625rem;
    align-self: stretch;
}

.aboutus-hero-text h1 {
    width: 41.25rem;
    color: var(--primary);
    /* font-family: Plus Jakarta Sans; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: right;
    margin: 0;
}

@media screen and (max-width: 800px) {
    #about-header-img {
        display: none;
    }

    .aboutus-hero-text {
        padding: 5.625rem 0;
    }

    .aboutus-hero-text h1 {
        width: auto;
    }

    #aboutus-hero {
        height: auto;
    }
}
