.footer {
    display: flex;
    justify-content: space-between;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 2rem 2.5rem 2rem;
    gap: 2rem;
}

.footer-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding-top: 2.5rem;
    border-top: 1px solid #d9d9d9;
    gap: 5rem;
}

.footer-left-container {
    display: flex;
    width: 28.09375rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
}

.footer-left-container img {
    max-width: 12rem;
}

.footer-left-container p {
    margin: 0;
    line-height: normal;
    font-size: 0.8rem;
}

.footer-ocf-container {
    display: flex;
    align-items: center;
    gap: 1.5625rem;
}

.footer-ocf-container img {
    max-width: 7rem;
}

.footer-ocf-container p {
    font-size: 0.7rem;
    margin: 0;
}

.footer-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5rem;
}

.footer-nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
}

.footer-nav-container a {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8rem;
    color: var(--text);
    text-decoration: none;
}

@media screen and (max-width: 950px) {
    .footer-ocf-container {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width: 700px) {
    .footer-left-container {
        width: 100%;
    }
    .footer-content {
        flex-direction: column;
    }

    .footer-right-container {
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }
}
