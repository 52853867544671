.header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.desktop-navbar {
    display: flex;
    overflow: hidden;
    height: 6.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.desktop-navbar img {
    max-width: 8rem;
}

.header-navbar {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.header-navbar a,
.mobile-navbar a {
    display: flex;
    overflow: hidden;
    padding: 1rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
}

.imglink {
    display: flex;
    align-items: center;
}

.link {
    color: var(--text);
}

.header-navbar img {
    display: none;
}

.mobile-navbar {
    display: none;
}

@media screen and (max-width: 950px) {
    .header-navbar a {
        display: none;
    }
    .header-navbar img {
        display: block;
        width: 2rem;
        cursor: pointer;
    }
    .mobile-navbar {
        display: flex;
        position: absolute;
        top: 6.5rem;
        left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        gap: 1.25rem;
        width: 100vw;
        background: var(--background);
        border-top: 1px solid var(--primary);
        border-bottom: 1px solid var(--primary);
        padding: 2rem;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.3s ease-in-out;
        overflow: hidden;
        z-index: 10;
    }

    .mobile-navbar.expanded {
        transform: scaleY(1);
    }

    .mobile-navbar .button {
        margin-top: 2rem;
        align-self: stretch;
        align-items: center;
    }
}
