@import url("https://fonts.googleapis.com/css?family=Plus Jakarta Sans");

:root {
    --primary: #4295af;
    --primary-light: #dde9ed;
    --primary-dark: #2f6a7d;
    --secondary: #c6daa8;
    --secondary-light: #f0f4ea;
    --background: #fafafa;
    --text: #303030;
    --fc-button-bg-color: var(--primary);
    --fc-button-border-color: var(--primary);
    --fc-button-hover-bg-color: var(--primary-dark);
    --fc-button-hover-border-color: var(--primary-dark);
    --fc-button-active-bg-color: var(--primary-dark);
    --fc-button-active-border-color: var(--primary-dark);
}

body {
    margin: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

.hero {
    display: flex;
    position: relative;
    height: 45rem;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 2rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.button {
    cursor: pointer;
    /* font-family: "Plus Jakarta Sans"; */
    background-color: var(--primary);
    color: var(--background);
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.3s;
    padding: 1rem 1.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
}

.button:hover {
    background-color: var(--primary-dark);
}

.container {
    padding: 3.75rem 0;
}

.content-container {
    display: flex;
    justify-content: space-between;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 2rem;
    gap: 2rem;
}

.content-container img {
    border-radius: 0.625rem;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid #d9d9d9;
}

.title-container h1 {
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    margin-bottom: 0.75rem;
}
