.officer-column-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.officer-row-container {
    display: flex;
    align-items: flex-start;
    gap: 10vw;
    width: 100%;
    justify-content: center;
}

.officer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.officer-container img {
    width: 12rem;
}

.officer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
}

.officer-text h1 {
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    margin: 0;
}

.officer-text p {
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .officer-row-container {
        flex-direction: column;
        gap: 5rem;
    }
    .officer-container {
        width: 100%;
    }
}
