.join-hero-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: stretch;
}

.join-hero-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* margin-right: 10rem; */
    gap: 3.75rem;
}

.join-hero-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.9375rem;
    align-self: stretch;
}

.join-hero-text-container h1 {
    align-self: stretch;
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 0;
}

#mobile-hero-image {
    display: none;
}

@media screen and (max-width: 1100px) {
    .join-hero-content-container {
        width: 50%;
    }
}

@media screen and (max-width: 1000px) {
    .join-hero-content-container {
        width: 100%;
    }

    #join-hero-image {
        display: none;
    }

    .join-hero-container {
        align-items: flex-start;
    }

    #mobile-hero-image {
        display: block;
        width: 100%;
    }

    .hero-section {
        height: auto;
    }

    #join-hero {
        height: auto;
    }

    .join-hero-content-container {
        margin: 7rem 0;
    }
}
