.home-hero-text {
    display: flex;
    height: 43.125rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 1.25rem;
}

.home-hero-text h2 {
    width: 30.5625rem;
    color: #4295af;
    /* font-family: Plus Jakarta Sans; */
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.home-hero-text h1 {
    width: 30.5625rem;
    color: #303030;
    /* font-family: Plus Jakarta Sans; */
    font-size: 4.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.hero-image img {
    position: absolute;
    top: 0;
    right: 2rem;
    height: 100%;
    z-index: 0;
}

.hero-image-mobile img {
    display: none;
}

@media screen and (max-width: 1000px) {
    #home-hero {
        height: auto;
    }
    .hero-image img {
        display: none;
    }

    .hero-image-mobile img {
        display: flex;
        width: 100%;
        height: auto;
    }

    .home-hero-text {
        height: 30rem;
    }

    .home-hero-text h1 {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .home-hero-text {
        height: 25rem;
    }

    .home-hero-text h2 {
        width: 100%;
        font-size: 1.25rem;
    }

    .home-hero-text h1 {
        font-size: 3rem;
    }
}
