/* About Blurp */
#about-blurp {
    background-color: var(--primary-light);
}

#about-blurp img {
    max-width: 45%;
}

.highlight-text-container {
    display: flex;
    max-width: 45%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2.8125rem;
}

.highlight-text-container p {
    align-self: stretch;
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem;
    margin: 0;
}

/*Values*/
#values-content {
    flex-direction: column;
    gap: 3.75rem;
}

.values-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
}

.value-container {
    display: flex;
    width: 17.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;
}

.value-text-container {
    display: flex;
    width: 17.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.9375rem;
}

.value-container h1 {
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.value-container p {
    align-self: stretch;
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
    margin: 0;
}

/* Join */
.join-section {
    margin: 5rem auto;
    padding: 0 2rem;
    max-width: 80rem;
}

.join-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-light);
    border: 3px solid var(--secondary);
    border-radius: 20px;
    padding: 2.5rem 3.75rem;
    gap: 2rem;
}

.join-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
}

.join-text h1 {
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.join-text p {
    align-self: stretch;
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    margin: 0;
}

@media screen and (max-width: 950px) {
    #about-blurp-content {
        flex-direction: column;
    }

    #about-blurp-content img {
        max-width: 100%;
    }

    .highlight-text-container {
        max-width: 100%;
    }

    .values-container {
        flex-direction: column;
        gap: 2.5rem;
    }

    .value-container {
        flex-direction: row;
        width: 100%;
        gap: 2.5rem;
    }

    .value-text-container {
        align-items: flex-start;
        width: 100%;
    }

    .value-container p {
        text-align: left;
    }

    .join-container {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
        align-items: flex-start;
    }
}

@media screen and (max-width: 600px) {
    .values-container {
        flex-direction: column;
        align-items: center;
        gap: 5rem;
    }

    .value-container {
        flex-direction: column;
    }

    .value-text-container {
        align-items: center;
        width: 100%;
    }

    .value-container p {
        text-align: center;
    }

    .join-text h1 {
        font-size: 1.5rem;
    }
}
