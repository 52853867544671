.hero-section {
    position: relative;
    height: 45rem;
}

#join-hero-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

#memberbenefits {
    background-color: var(--primary-light);
}

#benefits-container {
    align-items: center;
}

.benefits-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    max-width: 45%;
}

.benefits-text h1 {
    color: var(--primary);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    margin: 0;
}

.benefits-text li {
    color: var(--text);
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
}

ul {
    padding-inline-start: 25px;
    margin: 0;
}

#benefits-container img {
    max-width: 45%;
}

#calendar {
    max-width: 900px;
    margin: 0 auto;
}

#event-content {
    flex-direction: column;
}

.fc-daygrid-dot-event {
    color: var(--text);
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: 600;
}

/* Assuming DesktopCalendar and MobileCalendar have these classes */
.desktop-calendar {
    display: block; /* Show by default */
}

.mobile-calendar {
    display: none; /* Hide by default */
}

/* When screen width is below 750px */
@media screen and (max-width: 800px) {
    .desktop-calendar {
        display: none; /* Hide the desktop calendar */
    }
    .mobile-calendar {
        display: block; /* Show the mobile calendar */
    }
    .fc .fc-toolbar {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .fc .fc-toolbar-title {
        font-size: 1.25rem;
    }

    .fc .fc-button {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 750px) {
    #benefits-container {
        flex-direction: column-reverse;
    }

    #benefits-container img {
        max-width: 100%;
    }

    .benefits-text {
        max-width: 100%;
    }
}
